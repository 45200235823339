import { fetchSyllabus } from '@/fetchers'
import {
	Glossary,
	Syllabus,
	Weblinkext,
	Weblinkvideo,
} from '@/kontent/content-types'
import { contentTypes } from '@/kontent/project/contentTypes'
import {
	byTaxoCodename,
	getCommonFilteredGlossariesWithFilteredTags,
	getWebLinkWithoutAce,
	uniquePrimitiveArray,
} from '@/utils'
import { commonFetch } from '@/utils/fetchUtils'
import { Responses } from '@kontent-ai/delivery-sdk'
import { useQuery } from '@tanstack/react-query'
import { ReactNode, useEffect } from 'react'
import { Loading } from './Loading'
import SyllabusView, { SyllabusViewProps } from './SyllabusView'
import { useKontentHomeConfig } from './contexts/KontentHomeConfigProvider'

interface AsyncSyllabusViewProps
	extends Omit<
		SyllabusViewProps,
		'syllabus' | 'linkedItems' | 'allGlossaries'
	> {
	syllabusCodename: string
	slotBefore?: ReactNode
}

const fetchSyllabusCustom = async (codename: string) => {
	const [{ json, ok }] = await Promise.all([
		commonFetch<Responses.IViewContentItemResponse<Syllabus>, any>(
			`/api/item?codename=${codename}&depth=1`,
			undefined,
			{
				method: 'GET',
			},
		),
	])

	// specifically get the advanced course overview  and its children
	const responseSyllabusACO = await fetchSyllabus(
		codename,
		uniquePrimitiveArray([
			contentTypes.syllabus.elements.additional_content.codename,
			contentTypes.syllabus_overviewpage.codename,
			contentTypes.syllabus_overviewpage.elements.title.codename,
			contentTypes.syllabus_overviewpage.elements.content.codename,
			contentTypes.syllabus_overviewpage.elements.slug.codename,

			//possible components inside the overview page's content
			contentTypes.ui_media.codename,
			contentTypes.ui_media.elements.align.codename,
			contentTypes.ui_media.elements.caption.codename,
			contentTypes.ui_media.elements.image.codename,
			contentTypes.ui_media.elements.theme.codename,
			contentTypes.ui_media.elements.type.codename,
			contentTypes.ui_media.elements.video.codename,
			contentTypes.ui_media.elements.width.codename,

			contentTypes.contentrichtext.codename,
			contentTypes.contentrichtext.elements.content.codename,
			contentTypes.contentrichtext.elements.stage_years.codename,
			contentTypes.contentrichtext.elements.stages.codename,
			contentTypes.contentrichtext.elements.syllabus_type__items.codename,

			contentTypes.math_content.codename,
			contentTypes.math_content.elements.content.codename,

			contentTypes.ui_video_tile.codename,
			contentTypes.ui_video_tile.elements.caption.codename,
			contentTypes.ui_video_tile.elements.date.codename,
			contentTypes.ui_video_tile.elements.key_learning_area.codename,
			contentTypes.ui_video_tile.elements.syllabus.codename,
			contentTypes.ui_video_tile.elements.thumbnail.codename,
			contentTypes.ui_video_tile.elements.title.codename,
			contentTypes.ui_video_tile.elements.transcript.codename,
			contentTypes.ui_video_tile.elements.video_url.codename,

			contentTypes.ui_resources.codename,
			contentTypes.ui_resources.elements.resources.codename,
			contentTypes.ui_resources.elements.resource_containers.codename,
			...Object.values(contentTypes.resource_container.elements).map(
				(item) => item.codename,
			),
		]),
	)

	if (!ok) {
		throw new Error(
			'AsyncSyllabusView fetch: Network error on fetching syllabus',
		)
	}
	const { ok: okVideo, json: jsonVideo } = await commonFetch<
		Responses.IListContentItemsResponse<Weblinkvideo>,
		any
	>(
		`/api/items?type=${
			contentTypes.weblinkvideo.codename
		}&contains.element=elements.${
			contentTypes.weblinkvideo.elements.syllabus.codename
		}&contains.value=${json.item.elements.syllabus.value.map(
			byTaxoCodename,
		)}`,
	)

	if (!okVideo) {
		throw new Error(
			'AsyncSyllabusView fetch: Network error on fetching weblink videos',
		)
	}

	const { ok: okExternalLink, json: jsonExternalLink } = await commonFetch<
		Responses.IListContentItemsResponse<Weblinkext>,
		any
	>(
		`/api/items?type=${
			contentTypes.weblinkext.codename
		}&contains.element=elements.${
			contentTypes.weblinkext.elements.syllabus.codename
		}&contains.value=${json.item.elements.syllabus.value.map(
			byTaxoCodename,
		)}`,
	)
	if (!okExternalLink) {
		throw new Error(
			'AsyncSyllabusView fetch: Network error on fetching weblink external',
		)
	}

	const { ok: okGlobalGlossary, json: jsonGlobalGlossary } =
		await commonFetch<Responses.IListContentItemsResponse<Glossary>, any>(
			`/api/items?type=${contentTypes.glossary.codename}&empty.element=elements.${contentTypes.glossary.elements.type.codename},elements.${contentTypes.glossary.elements.syllabus.codename}`,
		)

	const { ok: okSyllabusGlossary, json: jsonSyllabusGlossary } =
		await commonFetch<Responses.IListContentItemsResponse<Glossary>, any>(
			`/api/items?type=${
				contentTypes.glossary.codename
			}&empty.element=elements.${
				contentTypes.glossary.elements.type.codename
			}&any.element=elements.${
				contentTypes.glossary.elements.syllabus.codename
			}&any.value=${json.item.elements.syllabus.value.map(
				byTaxoCodename,
			)}`,
		)

	return {
		syllabusResponse: {
			item: json.item,
			linkedItems: {
				...json.linkedItems,
				// stitch linked items, since the responseSyllabusACO is a separate fetch
				...responseSyllabusACO.linkedItems,
			},
		},
		webLinkVideosResponse: getWebLinkWithoutAce(jsonVideo),
		webLinkExternalLinks: getWebLinkWithoutAce(jsonExternalLink),
		glossaryResponse: {
			...(getCommonFilteredGlossariesWithFilteredTags(
				{
					items: [
						...jsonGlobalGlossary.items,
						...jsonSyllabusGlossary.items,
					],
					linkedItems: {
						...jsonGlobalGlossary.linkedItems,
						...jsonSyllabusGlossary.linkedItems,
					},
				} as Responses.IListContentItemsResponse<Glossary>,
				[json.item],
			) || {}),
		},
	}
}

export const AsyncSyllabusView = ({
	syllabusCodename,
	allAssets,
	allStageGroups,
	allStages,
	currentOptions,
	currentTabs,
	currentStages,
	initialStageCodename,
	initialTab,
	enableContentCurrentlyViewing = false,
	slotBefore,
}: AsyncSyllabusViewProps) => {
	const { data, isFetched } = useQuery(
		[`AsyncSyllabusView_${syllabusCodename}`, syllabusCodename],
		() => fetchSyllabusCustom(syllabusCodename),
		{
			staleTime: Infinity,
		},
	)
	const { pageResponseLinkedItems, setPageResponseLinkedItems } =
		useKontentHomeConfig()
	const {
		syllabusResponse,
		webLinkVideosResponse,
		webLinkExternalLinks,
		glossaryResponse,
	} = data || {}

	useEffect(() => {
		if (isFetched) {
			setPageResponseLinkedItems({
				...pageResponseLinkedItems,
				...syllabusResponse.linkedItems,
			})
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isFetched, syllabusResponse?.item.system.id])

	if (!isFetched) {
		return <Loading />
	}

	return (
		<>
			{slotBefore}
			<SyllabusView
				allAssets={allAssets}
				allGlossaries={
					glossaryResponse as unknown as Responses.IListContentItemsResponse<Glossary>
				}
				allStageGroups={allStageGroups}
				allStages={allStages}
				currentOptions={currentOptions}
				currentStages={currentStages}
				currentTabs={currentTabs}
				linkedItems={syllabusResponse.linkedItems}
				syllabus={syllabusResponse.item}
				initialStageCodename={initialStageCodename}
				initialTab={initialTab}
				enableContentCurrentlyViewing={enableContentCurrentlyViewing}
				allWebLinkVideos={webLinkVideosResponse.items}
				allWebLinkExternals={webLinkExternalLinks.items}
				showCurrentStagesTags
				isParentCustomView
			></SyllabusView>
		</>
	)
}
